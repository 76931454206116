import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DashNav from "../home/dashnav";
import useData, { Spinner } from "../utils/data";
import BanterButton from "../home/banterbutton";
import { useCliqueInvite } from "./cliqueinvite";
import { useTheme } from "../context/theme";

import { Pluralize } from "../utils/util";
import { useCliqueDetails } from "./cliquedetails";
import { clique, contest } from '../utils';
//import { useStartContest } from "./startcontest";

export default function Contests() {

    const themeContext = useTheme()
    const theme = themeContext.theme
    const data = useData()    
    
    const [cliques, setCliques] = useState<clique[]>([])
    const [isFetchingCliques, setIsFetchingCliques] = useState(false)
    const [isSubscribed, setIsSubscribed] = useState(true)
    const cliqueDetails = useCliqueDetails()    
    const [contests, setContests] = useState<contest[]>([])
    //const startContestPopup = useStartContest()

    const openClique = (clique: clique) => {
        cliqueDetails.show(clique)
    }

    const fetchCliques = () => {
        setIsFetchingCliques(true)
        data.get(process.env.REACT_APP_API_CLIQUES, (resp: any) => {
            if(isSubscribed){
                setIsFetchingCliques(false)
                if (resp && resp.data) {
                    const results = resp.data
                    setCliques(results)                    

                    // any contests in user's cliques?
                    let cliqueContests: contest[] = []
                    for(let i = 0; i < results.length; i++){
                        let clique = results[i]
                        cliqueContests = [ ...cliqueContests, ...clique.contests ]
                    }
                    
                    setContests(cliqueContests)
                }
            }
            
        }, () => setIsFetchingCliques(false))
    }

    const cliqueInvite = useCliqueInvite(fetchCliques)

    useEffect(() => {
        // fetch cliques
        fetchCliques()

        return ()=>{
            setIsSubscribed(false)
        }

    }, []) //eslint-disable-line react-hooks/exhaustive-deps
    return (
        <>
            <DashNav title="Contests">
                <div style={{minHeight:200}}>
                    <p className="mb-4 lh-1">                         
                    {
                            cliques.length === 0 &&
                            <>Your contests will show up here when you join a clique.</>
                        } {
                            cliques.length > 0 &&
                            <span>There <Pluralize single="is" plural="are" count={contests.length} /> { contests.length === 0 ? 'no' : contests.length } active <Pluralize single="contest" plural="contests" count={contests.length} /> in your <Pluralize single="clique" plural="cliques" count={cliques.length} />.</span>
                        }
                    </p>                                                            

                    <div className="row mb-5">
                        <div className="col-xs-12" style={{minHeight:100}}>                            

                            <div>                                                
                                <ul className="list-group list-group-flush mb-4">
                                    {
                                        cliques.map((clique, index) => {
                                            return (
                                                <li onClick={() => openClique(clique)} className="list-group-item border-0 d-flex justify-content-between align-items-start p-0 p-sm-2 pb-3" key={index}>
                                                    <Link to="#" style={{ opacity: clique.id === clique?.id ? '1' : '.5' }}>
                                                        <svg className="bd-placeholder-img rounded-circle"
                                                            width="50" height="50" xmlns="http://www.w3.org/2000/svg"
                                                            role="img" aria-label={clique.name} preserveAspectRatio="xMidYMid slice"
                                                            focusable="false">
                                                            <rect width="100%" height="100%" fill={'#ffecb5'}></rect>
                                                            <text x="50%" y="50%" dominantBaseline="middle" fontWeight="bolder" textAnchor="middle" fill={'#664d03'}>
                                                                {clique.name?.substring(0, 1)?.toUpperCase()}
                                                                {clique.name?.substring((clique.name.length - 1))?.toUpperCase()}
                                                            </text>
                                                        </svg>
                                                    </Link>
                                                    <div className={"ms-2 me-auto " + theme.color}>
                                                        <div className="fw-bold lh-1"><Link to="#">{clique.name}</Link></div>
                                                        <small className="text-truncate" style={{ display: 'inline-block' }}>
                                                            {
                                                                clique.friends?.length > 1 && <>{clique.friends?.length} </>
                                                            }
                                                            {
                                                                clique.friends?.length && <Pluralize count={clique.friends?.length} single='You' plural='friends' />
                                                            }
                                                        </small>
                                                    </div>
                                                    
                                                    {
                                                        clique.contests?.length > 0 &&
                                                        <span className="badge bg-primary rounded-pill">{clique.contests?.length} <i className="bi bi-award fs-6"></i></span>
                                                    }                                                    
                                                </li>
                                            )
                                        })
                                    }
                                    {
                                        cliques.length < 5 &&                                        
                                        <li onClick={() => cliqueInvite.show()} className="list-group-item border-0 d-flex align-items-start p-0 p-sm-2 pb-3">
                                            <Link to="#" style={{ opacity: '1' }}>
                                                <svg className="bd-placeholder-img rounded-circle"
                                                    width="50" height="50" xmlns="http://www.w3.org/2000/svg"
                                                    role="img" aria-label="New Clique" preserveAspectRatio="xMidYMid slice"
                                                    focusable="false">
                                                    <rect width="100%" height="100%" fill={'#ffecb5'}></rect>
                                                    <text x="50%" y="50%" dominantBaseline="middle" fontWeight="bolder" textAnchor="middle" fill={'#664d03'}>
                                                        N
                                                    </text>
                                                </svg>
                                            </Link>
                                            <div className={"ms-2 me-auto " + theme.color}>
                                                <div className="lh-1"><Link to="#">New</Link></div>
                                                <small className="text-truncate" style={{ display: 'inline-block' }}>
                                                    Clique or team
                                                </small>
                                            </div>
                                        </li> 
                                    }
                                    
                                </ul>
                            </div>

                        </div>

                        <div style={{minHeight:120}} className="border border-0">
                            {/* {
                                isFetchingCliques === false && cliques.length < 3 &&
                                <div className="text-center mb-5">
                                    <Link to="#" onClick={() => cliqueInvite.show()}>New clique</Link>
                                </div>
                            } */}

                            {
                                // cliques.length > 0 &&                            
                                // <div className="lh-1 text-center mt-3">
                                    
                                //     <p className="ps-5 pe-5">
                                //         <Link to="#" onClick={() => startContestPopup.show(fetchCliques)}>start a contest</Link> in your clique.
                                //     </p>

                                // </div>
                            }

                            {
                                isFetchingCliques && 
                                <Spinner visibility={data.isBusy()} />
                            }
                        </div>
                        
                    </div>
                </div>          

            </DashNav>

            <BanterButton />

        </>

    )
}